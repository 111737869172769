import {
  Arr,
  sanitizeArr
} from './Array.lib';
import Core from "./Core";
import Http from "./Http";
import {
  Obj
} from './Object.lib';
import {
  Str
} from './String.lib';

const defs = { RES: {} };

const toCamelCase = function (str) {
  return String(str)
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

const fetch = (key, cb) => {
  cb = cb instanceof Function ? cb : function () { };
  Http.get(
    Core.getApi("Definitions"),
    {
      filter: JSON.stringify({ where: { key }, limit: 1 }),
    },
    function onSuccess(response) {
      response = Object(response[0]);
      const result = (response.values || []).filter((it) => !it.archived);
      defs[key] = result;
      const resultLabels = {};
      const resultIds = {};
      result.forEach((item) => {
        resultLabels[item.id] = item.label;
        resultIds[toCamelCase(item.label)] = item.id;
      });
      defs[`${key}Labels`] = resultLabels;
      defs[`${key}Ids`] = resultIds;
      defs.RES[key] = response;
      cb(defs[key]);
    }
  );
};

const fetchAll = async () => {
  try {
    if (!Core.isLogged()) {
      [].forEach(category => formatSingleResponse(category));
    }
    else {
      let response = await Http.get(Core.getApi("Definitions"));
      if (Array.isArray(response)) {
        response.forEach(category => formatSingleResponse(category));
      }
    }
  }
  catch (error) {
    Core.showError(error);
  }
  return defs;
};

const formatSingleResponse = (response) => {
  const key = response.key;
  const result = (response.values || []).filter((it) => !it.archived);
  defs[key] = result;
  const resultLabels = {};
  const resultIds = {};
  result.forEach((item) => {
    resultLabels[item.id] = item.label;
    resultIds[toCamelCase(item.label)] = item.id;
    (item.alternativeNames || []).forEach((name) => {
      resultIds[toCamelCase(name)] = item.id;
    });
  });
  defs[`${key}Labels`] = resultLabels;
  defs[`${key}Ids`] = resultIds;
  defs.RES[key] = response;
};

const update = (defId, update, cb) => {
  cb = cb instanceof Function ? cb : function () { };
  return Http.patch(Core.getApi("Definitions/" + defId), update, (response) => {
    formatSingleResponse(response);
    cb(response);
    Core.log({ response });
  });
};

const post = (newDef, cb) => {
  cb = cb instanceof Function ? cb : function () { };
  newDef.label = String(newDef.label);
  newDef.key = newDef.key || toCamelCase(newDef.label);
  newDef.values = newDef.values || [];
  newDef.open = true;
  Http.post(Core.getApi("Definitions"), newDef, cb);
};

/**
 * 
 * @param {string} categoryKey 
 * @returns {object[]} Array of tags
 */
const get = (categoryKey) => {
  return defs[categoryKey] || [];
};

const getTag = ({ categoryKey = '', tagId = '', tagLabel = '' }) => {
  tagId = Str(tagId || getId(categoryKey, tagLabel));
  return Obj(Arr(defs[categoryKey]).find((category) => Str(category.id) === tagId));
};

/**
 * 
 * @param {object} options
 * @param {string} options.categoryKey
 * @param {number[]} options.tagIds
 * @returns {object[]} DefinitionTags
 */
function getTags({ categoryKey = '', tagIds = [], sortByArray = false }) {
  let indexes = {};
  tagIds.forEach((id, index) => indexes[id] = index);
  return JSON.parse(JSON.stringify(
    get(categoryKey).filter(n => tagIds.includes(n.id)).sort((a, b) => {
      if (sortByArray) {
        return indexes[a.id] - indexes[b.id];
      }
      else {
        return String(a.label).localeCompare(b.label);
      }
    })
  ));
}

const getRawDef = (key) => getRes()[key];
const getAll = (em) => Object.assign({}, defs);
const getRes = (em) => Object.assign({}, defs.RES);
const getLabel = (key, id) => {
  let tag = Object(defs[`${key}Labels`])[id];

  if (!tag) {
    tag = "";
  }

  return tag;
};

const getLabelWithId = (key, id) => {
  let tag = getLabel(key, id);
  return `${id}-${tag}`;
};

const getGroupObj = (key, groupId) => {
  let raw = getRawDef(key);
  let group = {};
  if (Array.isArray(Object(raw).groups)) {
    let groups = Object(raw).groups;
    group = groups.find((el) => el.id === groupId);
  }

  return group;
};
const getAllLabels = (key) => Object(defs[`${key}Labels`]);

/**
 * This is returning an array of Tag-labels,
 *
 * Also is updating the object in the dictionary param,
 * such as hash list with all values set in false,
 * to be used for the filtering menus checks.
 *
 * @param {string} categoryKey
 * @param {number[]} tagIds
 * @param {object} dictionary Object to updated
 * @returns {string[]} Labels found.
 */
function getLabels(categoryKey, tagIds = [], dictionary) {
  return sanitizeArr(
    tagIds.map(id => {
      const label = Definition.getLabel(categoryKey, id);
      if (label) {
        dictionary && (dictionary[label] = false);
        return label;
      }
      return '';
    })
  );
}

const getLabelsWithId = (key, arr, dictionary) =>
  Array.isArray(arr)
    ? arr.map((id) => {
      const label = Definition.getLabelWithId(key, id);
      return `${label}`;
    })
    : [];
// [TODO: Need to get by entity]
const getLabelByEntity = (key, entity, id) => Object(defs[`${key}Labels`])[id];

/**
 * Returns the Tag matching with a label.
 * 
 * @param {string} categoryKey 
 * @param {string} label 
 * @returns {number} TagId
 */
function getId(categoryKey = '', label = '') {
  return Number(Object(defs[`${categoryKey}Ids`])[toCamelCase(label)]);
}

const test = (key, index, expression) =>
  new RegExp(expression, "i").test(Definition.getLabel(key, index));

const set = (obj, key, field) => {
  field = field || key;
  obj[`_${field}`] = String(Definition.getLabel(key, obj[field])).trim();
};

/**
 * This method finds the definitions labels matched a Entity field.
 * sets the result as an string in the Entity with the field name prefixing an underscore.
 * ex. Entity.fieldTags = Entity._fieldTags.
 *
 * @param {object} obj Entity
 * @param {string} key Definition key
 * @param {string} field Entity.field
 * @returns {string} csv with an extra space to be used in UI also.
 */
function map(obj, key, field) {
  field = field || key;
  obj[`_${field}`] = Definition.getLabels(
    key,
    obj[field],
    (obj[`_${field}Keys`] = {})
  ).join(", ");
}

/**
 *
 * @param {*} em
 *
 * @todo
 *
 * TO REVIEW
 *
 * Assumptions:
 * - This method was created to translate
 *   the old category tag system (previous 2018)
 *   to the new tag management system.
 * - This probably could be deprecated and cleanup.
 *
 */
const updateTableStates = (em) => {
  const state = {
    "5a345fe48b6a8325807a06f3": 1, //Active
    "5a345fe48b6a8325807a072f": 2, //Draft
    "5a345fe48b6a8325807a075e": 3, //Hold
    "5a345fe48b6a8325807a0765": 4, //Inactive
    "5ae1e3534b6f0513bde2031f": 5, //Lead
  };
  const jobType = {
    "5a345fe48b6a8325807a0714": 1, // Co-founder
    "5a345fe48b6a8325807a071a": 2, // Contract
    "5a345fe48b6a8325807a074b": 3, // Fulltime
    "5a345fe48b6a8325807a076b": 4, // Internship
    "5a345fe48b6a8325807a07a3": 5, // Part Time
  };
  const role = {
    "5a345fe48b6a8325807a0703": 1, //Backend Engineer
    "5a640473be9dc917a627052d": 2, //Computer Vision
    "5a81de9ad9e3667ace455e01": 3, //Customer Service
    "5abbe4bf131a80222bde2de7": 4, //Data Analyst
    "5a345fe48b6a8325807a0723": 5, //Data Engineer
    "5a345fe48b6a8325807a0725": 6, //Data Scientist
    "5a345fe48b6a8325807a0727": 7, //Designer
    "5a345fe48b6a8325807a0729": 8, //DevOps Engineer
    "5a345fe48b6a8325807a0749": 9, //Frontend Engineer
    "5a345fe48b6a8325807a074a": 10, //Fullstack Engineer
    "5a345fe48b6a8325807a0768": 11, //Infrastructure Engineer
    "5a345fe48b6a8325807a0785": 12, //Manager
    "5a345fe48b6a8325807a0788": 13, //Marketing
    "5a345fe48b6a8325807a0791": 14, //Mobile Engineer
    "5a642cd5be9dc917a627058d": 15, //Performance Engineer
    "5a345fe48b6a8325807a07ae": 16, //Product Manager
    "5a345fe48b6a8325807a07b0": 17, //Program Manager
    "5a7931b9b068af7d47f583fb": 18, //Project Manager
    "5abbe4dc131a80222bde2df1": 19, //QA
    "5a345fe48b6a8325807a07bc": 20, //Recruiter
    "5a345fe48b6a8325807a07c1": 21, //Sales
    "5a6677e8be9dc917a62709bb": 22, //Security Engineer
  };
  const remote = {
    "5a345fe48b6a8325807a07d0": 1, // Case by Case
    "5a345fe48b6a8325807a0797": 2, // No
    "5a345fe48b6a8325807a06eb": 3, // Unknown
    "5a345fe48b6a8325807a07e9": 4, // Yes
  };
  const stage = {
    "5a345fe48b6a8325807a0779": 1, // Late Stage Startup
    "5a345fe48b6a8325807a07b2": 2, // Public
    "5a345fe48b6a8325807a07c8": 3, // Seed
    "5a345fe48b6a8325807a07c9": 4, // Series A
    "5a345fe48b6a8325807a07ca": 5, // Series B
    "5a345fe48b6a8325807a07cb": 6, // Series C
    "5a345fe48b6a8325807a07cc": 7, // Series D+
  };
  const categories = {
    roles: {
      "5a345fe48b6a8325807a0703": 1, //Backend Engineer
      "5a640473be9dc917a627052d": 2, //Computer Vision
      "5a81de9ad9e3667ace455e01": 3, //Customer Service
      "5abbe4bf131a80222bde2de7": 4, //Data Analyst
      "5a345fe48b6a8325807a0723": 5, //Data Engineer
      "5a345fe48b6a8325807a0725": 6, //Data Scientist
      "5a345fe48b6a8325807a0727": 7, //Designer
      "5a345fe48b6a8325807a0729": 8, //DevOps Engineer
      "5a345fe48b6a8325807a0749": 9, //Frontend Engineer
      "5a345fe48b6a8325807a074a": 10, //Fullstack Engineer
      "5a345fe48b6a8325807a0768": 11, //Infrastructure Engineer
      "5a345fe48b6a8325807a0785": 12, //Manager
      "5a345fe48b6a8325807a0788": 13, //Marketing
      "5a345fe48b6a8325807a0791": 14, //Mobile Engineer
      "5a642cd5be9dc917a627058d": 15, //Performance Engineer
      "5a345fe48b6a8325807a07ae": 16, //Product Manager
      "5a345fe48b6a8325807a07b0": 17, //Program Manager
      "5a7931b9b068af7d47f583fb": 18, //Project Manager
      "5abbe4dc131a80222bde2df1": 19, //QA
      "5a345fe48b6a8325807a07bc": 20, //Recruiter
      "5a345fe48b6a8325807a07c1": 21, //Sales
      "5a6677e8be9dc917a62709bb": 22, //Security Engineer
    },
    technicalSkills: {
      "5a345fe48b6a8325807a06e2": 1, // .Net
      "5a345fe48b6a8325807a06fe": 2, // API
      "5a345fe48b6a8325807a0700": 3, // AWS
      "5a656e28be9dc917a627077b": 4, // Agnostic
      "5a661706be9dc917a62708a7": 5, // Airflow
      "5a345fe48b6a8325807a06fb": 6, // Android
      "5a345fe48b6a8325807a06fc": 7, // Angular
      "5a345fe48b6a8325807a06fd": 8, // Ansible
      "5a655c93be9dc917a62706fd": 9, // Babel
      "5a345fe48b6a8325807a0702": 10, // Backbone
      "5a345fe48b6a8325807a0704": 11, // Bash
      "5a792f17b068af7d47f583fa": 12, // BigQuery
      "5a345fe48b6a8325807a0708": 13, // Bootstrap
      "5a664f8bbe9dc917a6270935": 14, // Boto
      "5a345fe48b6a8325807a0709": 15, // C
      "5a345fe48b6a8325807a070b": 16, // C#
      "5a345fe48b6a8325807a070c": 17, // C++
      "5a345fe48b6a8325807a071d": 18, // CSS
      "5a655248be9dc917a62706cd": 19, // CV
      "5a345fe48b6a8325807a070d": 20, // Cassandra
      "5a345fe48b6a8325807a070e": 21, // Celery
      "5a345fe48b6a8325807a070f": 22, // Chef
      "5a664f7dbe9dc917a6270933": 23, // CircleCI
      "5a345fe48b6a8325807a0711": 24, // Clojure
      "5a345fe48b6a8325807a0713": 25, // Cloudera
      "5a345fe48b6a8325807a0716": 26, // Coffeescript
      "5a664f68be9dc917a6270932": 27, // Consul
      "5a792eefb068af7d47f583f7": 28, // Continuous Deployment (CD)
      "5a792ed9b068af7d47f583f6": 29, // Continuous Integration (CI)
      "5a345fe48b6a8325807a071b": 30, // Cordova
      "5a792ebbb068af7d47f583f4": 31, // CoreAnimation
      "5a792ecab068af7d47f583f5": 32, // CoreText
      "5a345fe48b6a8325807a0720": 33, // D3
      "5a6616e7be9dc917a62708a4": 34, // Dagger 2
      "5a345fe48b6a8325807a072d": 35, // Django
      "5a345fe48b6a8325807a072e": 36, // Docker
      "5a345fe48b6a8325807a0731": 37, // DynamoDB
      "5a655c73be9dc917a62706fc": 38, // ES6
      "5a345fe48b6a8325807a0736": 39, // ElasticSearch
      "5a345fe48b6a8325807a0738": 40, // Elixir
      "5a345fe48b6a8325807a0739": 41, // Ember
      "5a345fe48b6a8325807a073d": 42, // Erlang
      "5a345fe48b6a8325807a073f": 43, // Express
      "5a664f94be9dc917a6270936": 44, // Fabric
      "5a345fe48b6a8325807a0745": 45, // Flask
      "5a345fe48b6a8325807a074e": 46, // Golang
      "5a792effb068af7d47f583f8": 47, // Google Analytics
      "5a345fe48b6a8325807a074f": 48, // Google Cloud Engine
      "5a345fe48b6a8325807a0751": 49, // GraphQL
      "5a345fe48b6a8325807a0759": 50, // HDFS
      "5a345fe48b6a8325807a0763": 51, // HTML
      "5a345fe48b6a8325807a0764": 52, // HTML5
      "5a345fe48b6a8325807a0754": 53, // Hadoop
      "5a345fe48b6a8325807a0757": 54, // Haskell
      "5a345fe48b6a8325807a0758": 55, // Hbase
      "5a345fe48b6a8325807a075c": 56, // Heroku
      "5a345fe48b6a8325807a075d": 57, // Hive
      "5a345fe48b6a8325807a075f": 58, // Hortonworks
      "5a655250be9dc917a62706ce": 59, // JNI
      "5a345fe48b6a8325807a076e": 60, // Java
      "5a345fe48b6a8325807a076f": 61, // Javascript
      "5a345fe48b6a8325807a0770": 62, // Jenkins
      "5a92cacf8d478426c57311b6": 63, // Jest
      "5a345fe48b6a8325807a0774": 64, // Kafka
      "5a642e29be9dc917a627058e": 65, // Kernel
      "5a66170cbe9dc917a62708a8": 66, // Kibana
      "5a6616bcbe9dc917a62708a1": 67, // Kotlin
      "5a345fe48b6a8325807a0775": 68, // Kubernetes
      "5a345fe48b6a8325807a0776": 69, // LAMP
      "5a62918ba63116493d9c8e33": 70, // Lambda
      "5a345fe48b6a8325807a0780": 71, // Linux
      "5a661716be9dc917a62708a9": 72, // Logstash
      "5a345fe48b6a8325807a0787": 73, // MapReduced
      "5a345fe48b6a8325807a078a": 74, // Matlab
      "5a64229cbe9dc917a6270546": 75, // Memcached
      "5a345fe48b6a8325807a0790": 76, // Meteor
      "5a792f0ab068af7d47f583f9": 77, // Mixpanel
      "5a92cada8d478426c57311b7": 78, // Mocha
      "5a345fe48b6a8325807a0792": 79, // MongoDB
      "5a345fe48b6a8325807a0793": 80, // MySQL
      "5a664f9bbe9dc917a6270937": 81, // New Relic
      "5a345fe48b6a8325807a079b": 82, // NoSQL
      "5a345fe48b6a8325807a0798": 83, // Node
      "5a345fe48b6a8325807a079f": 84, // Objective-C
      "5a345fe48b6a8325807a07a1": 85, // Oracle
      "5a345fe48b6a8325807a07aa": 86, // PHP
      "5a345fe48b6a8325807a07a8": 87, // Perl
      "5a65474abe9dc917a6270644": 88, // PostGIS
      "5a345fe48b6a8325807a07ad": 89, // PostgresSQL
      "5a345fe48b6a8325807a07b4": 90, // Puppet
      "5a345fe48b6a8325807a07b5": 91, // Python
      "5a345fe48b6a8325807a07b7": 92, // R
      "5a642b18be9dc917a627057c": 93, // REST
      "5a65525ebe9dc917a62706d0": 94, // RSpec
      "5a642371be9dc917a6270548": 95, // RabbitMQ
      "5a345fe48b6a8325807a07b8": 96, // Rails
      "5a345fe48b6a8325807a07b9": 97, // React
      "5a654737be9dc917a6270643": 98, // React Native
      "5a6422a3be9dc917a6270547": 99, // Redis
      "5a62919ea63116493d9c8e35": 100, // Redshift
      "5a629196a63116493d9c8e34": 101, // Redux
      "5a6616dabe9dc917a62708a3": 102, // Retrofit/OkHttp
      "5a65533fbe9dc917a62706d3": 103, // Robotics
      "5a6616f0be9dc917a62708a5": 104, // Room
      "5a345fe48b6a8325807a07be": 105, // Ruby
      "5a345fe48b6a8325807a07bf": 106, // Rust
      "5a6616c5be9dc917a62708a2": 107, // RxJava
      "5a6291a4a63116493d9c8e36": 108, // S3
      "5a655274be9dc917a62706d2": 109, // SLAM
      "5a345fe48b6a8325807a07d6": 110, // SQL
      "5a345fe48b6a8325807a07c2": 111, // Salt
      "5a345fe48b6a8325807a07c4": 112, // Scala
      "5a345fe48b6a8325807a07ce": 113, // Sinatra
      "5a345fe48b6a8325807a07d1": 114, // Solr
      "5a345fe48b6a8325807a07d3": 115, // Spark
      "5a345fe48b6a8325807a07d5": 116, // Spring
      "5a345fe48b6a8325807a07d9": 117, // Swift
      "5a655258be9dc917a62706cf": 118, // TDD
      "5a345fe48b6a8325807a07dd": 119, // Tensor Flow
      "5a345fe48b6a8325807a07de": 120, // Tornado
      "5a345fe48b6a8325807a07e2": 121, // TypeScript
      "5a6291aba63116493d9c8e37": 122, // UI
      "5a792eb2b068af7d47f583f3": 123, // UIKit
      "5a6291b0a63116493d9c8e38": 124, // UX
      "5a345fe48b6a8325807a07e4": 125, // Unity
      "5a664f84be9dc917a6270934": 126, // Vagrant
      "5a792ea7b068af7d47f583f2": 127, // Vue
      "5a345fe48b6a8325807a07e8": 128, // Yarn
      "5a65526abe9dc917a62706d1": 129, // ZeroMQ
      "5a6616fdbe9dc917a62708a6": 130, // Zookeeper
      "5a345fe48b6a8325807a076d": 131, // iOS
      "5a345fe48b6a8325807a0772": 132, // jQuery
    },
    experience: {
      "5a345fe48b6a8325807a0784": 1, //1st Line Manager
      "5a6277aba63116493d9c8de6": 2, //2nd Line Manager
      "5a6277c6a63116493d9c8de7": 3, //Architect
      "5a345fe48b6a8325807a072b": 4, //Director
      "5a6277d3a63116493d9c8de8": 5, //Founder
      "5a345fe48b6a8325807a0748": 6, //Founding Team
      "5a6277f0a63116493d9c8de9": 7, //Head of Engineering
      "5a345fe48b6a8325807a0766": 8, //Individual Contributor
      "5a627805a63116493d9c8dea": 9, //Intern
      "5a627812a63116493d9c8deb": 10, //Junior
      "5a345fe48b6a8325807a077a": 11, //Lead
      "5a62781ea63116493d9c8dec": 12, //Mid-level
      "5a62782ea63116493d9c8ded": 13, //Principal
      "5a627836a63116493d9c8dee": 14, //Senior
      "5a345fe48b6a8325807a07d7": 15, //Startup
      "5a345fe48b6a8325807a07e7": 16, //VP
    },
    positiveSignals: {
      "5a345fe48b6a8325807a07a4": 1, // Aces Coding Interviews
      "5a345fe48b6a8325807a06ff": 2, // Award
      "5a628afba63116493d9c8e28": 3, // CS/CE degree
      "5a8731e7174e18399045098b": 4, // Diversity Candidate
      "5a345fe48b6a8325807a0732": 5, // Eagle Scout
      "5a345fe48b6a8325807a0747": 6, // Founding Engineer
      "5a345fe48b6a8325807a074d": 7, // Github Stars / Followers
      "5a345fe48b6a8325807a0753": 8, // Hackathon Winner
      "5a628b31a63116493d9c8e29": 9, // High GPA
      "5a345fe48b6a8325807a076c": 10, // Interview Team
      "5a345fe48b6a8325807a078e": 11, // Mentor
      "5a345fe48b6a8325807a07a0": 12, // Open Source Contributor
      "5a345fe48b6a8325807a07a5": 13, // Patents
      "5a345fe48b6a8325807a07b1": 14, // Promotion
      "5a345fe48b6a8325807a07b3": 15, // Publications
      "5a628b57a63116493d9c8e2a": 16, // STEM degree
      "5a345fe48b6a8325807a07cd": 17, // Side Projects
      "5a628b83a63116493d9c8e2c": 18, // Startup experience
      "5a628b74a63116493d9c8e2b": 19, // Strong Communication
      "5a628be4a63116493d9c8e31": 20, // Strong Company
      "5a628bc0a63116493d9c8e2d": 21, // Top 10 School
      "5a628bcca63116493d9c8e2f": 22, // Top 100 School
      "5a628bc7a63116493d9c8e2e": 23, // Top 50 School
      "5a628bdba63116493d9c8e30": 24, // Top Company
    },
    negativeSignals: {
      "5a345fe48b6a8325807a0715": 1, // Coding Bootcamp
      "5a345fe48b6a8325807a073a": 2, // Employement Gap
      "5a345fe48b6a8325807a0771": 3, // Job Hopper
      "5a628799a63116493d9c8e26": 4, // Lacking 4-year Degree
      "5a345fe48b6a8325807a0777": 5, // Large Company Lifer
      "5a628778a63116493d9c8e25": 6, // Outdated Technology
      "5a345fe48b6a8325807a07ac": 7, // Poor Communication
      "5a345fe48b6a8325807a07e3": 8, // Typos
    },
    flags: {
      "5a345fe48b6a8325807a0707": 1, // Bonus
      "5a345fe48b6a8325807a0741": 2, // Fast
      "5a345fe48b6a8325807a0760": 3, // Hot
    },
  };
  const location = {
    "5a345fe48b6a8325807a0733": 1, //East Bay
    "5a345fe48b6a8325807a079a": 2, //North Bay
    "5a345fe48b6a8325807a07a7": 3, //Peninsula
    "5a345fe48b6a8325807a07c3": 4, //San Francisco
    "5a345fe48b6a8325807a07d2": 5, //South Bay
  };
  const count = {
    "5a62570ba63116493d9c8ce9": 5, // 1 - 5",
    "5a62571ca63116493d9c8cea": 10, // 6 - 10",
    "5a345fe48b6a8325807a06e4": 25, // 11 - 25",
    "5a345fe48b6a8325807a06e5": 50, // 26 - 50",
    "5a345fe48b6a8325807a06e6": 100, // 51 - 100",
    "5a345fe48b6a8325807a06e7": 200, // 101 - 250",
    "5a345fe48b6a8325807a06e8": 300, // 251 - 500",
    "5a62572fa63116493d9c8ceb": 500, // 500+",
  };

  /** UPDATE JOBS */
  Http.get(
    Core.getApi("Jobs"),
    {
      filter: JSON.stringify({
        include: [
          {
            jobCategories: [
              "tagCategory",
              {
                jobCategoryTags: "tag",
              },
            ],
          },
        ],
      }),
    },
    function onSuccess(results) {
      // Core.log(results);
      const categoryNames = {};
      const next = (em) => {
        if (results.length) {
          const job = results.pop();

          job.jobStateTagId && (job.state = state[job.jobStateTagId]);
          job.jobJobTypeTagId && (job.jobType = jobType[job.jobJobTypeTagId]);
          job.jobRoleTagId && (job.role = role[job.jobRoleTagId]);
          job.jobRemoteTagId && (job.remote = remote[job.jobRemoteTagId]);
          job.jobStageTagId && (job.stage = stage[job.jobStageTagId]);

          job.jobCategories.forEach((cat) => {
            const catKey = toCamelCase(cat.tagCategory.name).replace(/\W/g, "");
            let newArr = [];
            cat.jobCategoryTags.forEach((tag) => {
              if (categories[catKey] && categories[catKey][tag.tag.id]) {
                newArr.push(categories[catKey][tag.tag.id]);
              }
            });
            if (!!newArr.length) {
              categoryNames[catKey] = true;
              job[catKey] = newArr;
            }
          });

          const flags = [];
          job.flagTagIds.forEach((id) => {
            categories.flags[id] && flags.push(categories.flags[id]);
          });
          if (!!flags.length) {
            job.flags = flags;
          }

          Http.patch(Core.getApi("Jobs/" + job.id), job, next, next);
        } else {
          Core.log({ categoryNames });
        }
      };
      next();
    }
  );

  /** UPDATE EMPLOYERS */
  Http.get(
    Core.getApi("Employers"),
    {
      filter: JSON.stringify({
        include: [
          {
            employerCategories: [
              "tagCategory",
              {
                employerCategoryTags: "tag",
              },
            ],
          },
        ],
      }),
    },
    function onSuccess(results) {
      const categoryNames = {};
      const next = (em) => {
        if (results.length) {
          const employer = results.pop();
          employer.employerStateTagId &&
            (employer.state = state[employer.employerStateTagId]);
          employer.employerRemoteTagId &&
            (employer.remote = remote[employer.employerRemoteTagId]);
          employer.employerStageTagId &&
            (employer.stage = stage[employer.employerStageTagId]);
          employer.employerLocationTagId &&
            (employer.location = location[employer.employerLocationTagId]);
          if (employer.employerEmployeeCountTagId && !employer.employeeCount) {
            employer.employeeCount = count[employer.employerEmployeeCountTagId];
          }
          if (employer.employerTeamCountTagId && !employer.teamCount) {
            employer.teamCount = count[employer.employerTeamCountTagId];
          }
          employer.employerCategories.forEach((cat) => {
            const catKey = toCamelCase(cat.tagCategory.name).replace(/\W/g, "");
            let newArr = [];
            cat.employerCategoryTags.forEach((tag) => {
              if (categories[catKey] && categories[catKey][tag.tag.id]) {
                newArr.push(categories[catKey][tag.tag.id]);
              }
            });
            if (!!newArr.length) {
              categoryNames[catKey] = true;
              employer[catKey] = newArr;
            }
          });

          const flags = [];
          employer.flagTagIds.forEach((id) => {
            categories.flags[id] && flags.push(categories.flags[id]);
          });
          if (!!flags.length) {
            employer.flags = flags;
          }

          Http.patch(
            Core.getApi("Employers/" + employer.id),
            employer,
            next,
            next
          );
        }
      };
      next();
    }
  );

  /** UPDATE ACCOUNTS */
  Http.get(
    Core.getApi("Accounts"),
    {
      filter: JSON.stringify({}),
    },
    function onSuccess(results) {
      const next = (em) => {
        if (results.length) {
          const account = results.pop();
          account.accountStateTagId &&
            (account.state = state[account.accountStateTagId]);

          Http.patch(
            Core.getApi("Accounts/" + account.id),
            account,
            next,
            next
          );
        }
      };
      next();
    }
  );

  /** UPDATE CANDIDATES */
  Http.get(
    Core.getApi("Candidates"),
    {
      filter: JSON.stringify({
        include: [
          {
            candidateCategories: [
              "tagCategory",
              {
                candidateCategoryTags: "tag",
              },
            ],
          },
        ],
      }),
    },
    function onSuccess(results) {
      // Core.log(results);
      const categoryNames = {};
      const next = (em) => {
        if (results.length) {
          const candidate = results.pop();

          /** 
           * @todo 
           * this looks like retro-compatibility patch, 
           * review if still need it, 
           * if not mark to cleanup 
           * 
           * 2021-07-30 µ
           */
          candidate.candidateStateTagId &&
            (candidate.state = state[candidate.candidateStateTagId]);

          candidate.candidateCategories.forEach((cat) => {
            const catKey = toCamelCase(cat.tagCategory.name).replace(/\W/g, "");
            let newArr = [];
            cat.candidateCategoryTags.forEach((tag) => {
              if (categories[catKey] && categories[catKey][tag.tag.id]) {
                newArr.push(categories[catKey][tag.tag.id]);
              }
            });
            if (!!newArr.length) {
              categoryNames[catKey] = true;
              candidate[catKey] = newArr;
            }
          });

          const jobTypes = [];
          candidate.jobTypeIds &&
            candidate.jobTypeIds.forEach((id) => {
              jobType[id] && jobTypes.push(jobType[id]);
            });
          if (!!jobTypes.length) {
            candidate.jobTypes = jobTypes;
          }

          Http.patch(
            Core.getApi("Candidates/" + candidate.id),
            candidate,
            next,
            next
          );
        } else {
          Core.log({ categoryNames });
        }
      };
      next();
    }
  );
};

const Definition = {
  fetch,
  fetchAll,
  getRawDef,
  get,
  getTag,
  getTags,
  getAll,
  getRes,
  getLabel,
  getGroupObj,
  getLabels,
  getAllLabels,
  getLabelByEntity,
  getId,
  update,
  post,
  test,
  set,
  map,
  updateTableStates,
  getLabelWithId,
  getLabelsWithId,
};

export default Definition;

// Set for debug purposes
window.Definition = Definition;

/* µ NOTE Constants defined here according to the context  */

export const ROLES__GROUP__TECHNICAL_ID = 1655321590407;


// CATEGORIES
export const DEFINITION_CATEGORY__ACCOUNT_ACTION = 'accountAction';
export const DEFINITION_CATEGORY__ACCOUNT_ANNOUNCEMENTS = 'accountAnnouncements';
export const DEFINITION_CATEGORY__ACCOUNT_EXPERTISE = 'accountExpertise';
export const DEFINITION_CATEGORY__ACCOUNT_INTERACTIONS = 'accountInteractions';
export const DEFINITION_CATEGORY__ATS_TYPES = 'aTSTypes';
export const DEFINITION_CATEGORY__DIVERSITY_CATEGORIES = 'diversityCategories';
export const DEFINITION_CATEGORY__EMPLOYER_SUBMISSION_METHOD = 'employerSubmissionMethod';
export const DEFINITION_CATEGORY__EMPLOYER__STAGE = 'stage';
export const DEFINITION_CATEGORY__ENGAGEMENT_MATCH_STRENGTH = 'engagementMatchStrength';
export const DEFINITION_CATEGORY__LOCATION = 'location';
export const DEFINITION_CATEGORY__POSITIVE_SIGNALS = 'positiveSignals';
export const DEFINITION_CATEGORY__STATE = 'state';
export const DEFINITION_CATEGORY__TECHNICAL_SKILLS = 'technicalSkills';
export const DEFINITION_CATEGORY__UNDERGRADUATE_DEGREE = 'undergraduateDegree';
export const DEFINITION_CATEGORY__VISA = 'visa';

// GROUPS
export const DEFINITION_GROUP_ID__TECHNICAL_SKILLS__FRONTEND = 1572308018066;
export const DEFINITION_GROUP_ID__TECHNICAL_SKILLS__BACKEND = 1572314081662;
export const DEFINITION_GROUP_ID__TECHNICAL_SKILLS__NO_SQL = 1572315192211;
export const DEFINITION_GROUP_ID__TECHNICAL_SKILLS__SQL_TECH = 1572364355352;
export const DEFINITION_GROUP_ID__TECHNICAL_SKILLS__CLOUD_TECH = 1573847732031;

// aTSType =======================================
export const ATS_TYPE__NONE_ID = 4;
export const ATS_TYPE__GH_MANUALLY_ID = 1;
export const ATS_TYPE__LEVER_ID = 2;
export const ATS_TYPE__LEVER_API_ID = 11;
export const ATS_TYPE__GH_INGESTION_ID = 9;
export const ATS_TYPE__GH_HARVEST_ID = 10;

// employerSubmissionMethod ====================== 
export const EMPLOYER_SUBMISSION_METHOD__EMAIL = 1;
export const EMPLOYER_SUBMISSION_METHOD__ATS = 2;

// machineLearningModel ==========================
export const MLM__HARDCODED_ID = 1;
export const MLM__NO_MODEL_ID = 2;

// recruiterMessageType ==========================
export const REC_MSG_TYPE__ALL_ID = 1;
export const REC_MSG_TYPE__REPRESENTATION_ID = 2;
export const REC_MSG_TYPE__REMINDER_ID = 3;
export const REC_MSG_TYPE__STAGE_TRANSITION_ID = 4;
export const REC_MSG_TYPE__REJECTION_ID = 5;
export const REC_MSG_TYPE__MESSAGE_ID = 6;

// employerMessageType ===========================
export const EMP_MSG_TYPE__ALL_ID = 1;
export const EMP_MSG_TYPE__SUBMISSION_ID = 2;
export const EMP_MSG_TYPE__REMINDER_ID = 3;
export const EMP_MSG_TYPE__SCHEDULE_ID = 4;

// location ======================================
export const LOCATION__EMP_HIRING_LOCS_ID = -1;
export const LOCATION__AMERICAS = 14;
export const LOCATION__NORTH_AMERICA = 15;
export const LOCATION__UNITED_STATES = 20;
export const LOCATION__CALIFORNIA = 26;
export const LOCATION__SAN_FRANCISCO_BAY_AREA = 88;

// locationType ==================================
export const LOC_TYPE__ALIAS_ID = 1;
export const LOC_TYPE__MULTI_CONT_ID = 2;
export const LOC_TYPE__CONTINENT_ID = 3;
export const LOC_TYPE__CONT_SECTION_ID = 4;
export const LOC_TYPE__COUNTRY_ID = 5;
export const LOC_TYPE__REGION_ID = 6;
export const LOC_TYPE__STATE_ID = 7;
export const LOC_TYPE__STATE_SECTION_ID = 11;
export const LOC_TYPE__METRO_AREA_ID = 8;
export const LOC_TYPE__METRO_AREA_SEC_ID = 9;
export const LOC_TYPE__CITY_ID = 10;

// inOfficeRemote ================================
export const IN_OFFICE_REMOTE__FULL_WEEK_ID = 1;
export const IN_OFFICE_REMOTE__PART_WEEK_ID = 2;
export const IN_OFFICE_REMOTE__REMOTE_ONLY_ID = 3;

/** @todo following Definition doesn't exist on the db catalog */
// relocation ====================================
export const RELOCATION__YES_ID = 1;
export const RELOCATION__NO_ID = 2;
export const RELOCATION__UNKNOWN_ID = 3;
export const RELOCATION__LABELS = {
  1: "Yes",
  2: "No",
  3: "Unknown"
}

// state =========================================
export const STATE_ACTIVE = 1;
export const STATE_DRAFT = 2;
export const STATE_HOLD = 3;
export const STATE_INACTIVE = 4;
export const STATE_LEAD = 5;

// matchExclusion ================================
export const MATCH_EXCLUSION__10X10_PLACEMENT = 1;
export const MATCH_EXCLUSION__RECRUITER_OWN_PLACEMENT = 2;
export const MATCH_EXCLUSION__NEW_JOB_ANOTHER_SOURCE = 3;
export const MATCH_EXCLUSION__STAY_AT_JOB = 4;
export const MATCH_EXCLUSION__PAUSE = 5;
export const MATCH_EXCLUSION__DO_NOT_MATCH = 6;
export const MATCH_EXCLUSION__OTHER = 7;
export const MATCH_EXCLUSION__POTENTIAL_FAKE = 8;
export const MATCH_EXCLUSION__UNRESPONSIVE = 9;

// platformRating ================================
export const PLATFORM_RATING__A_PLUS = 5;
export const PLATFORM_RATING__A_TOP = 1;
export const PLATFORM_RATING__B_STRONG = 2;
export const PLATFORM_RATING__C_GOOD = 3;
export const PLATFORM_RATING__D_STRETCH = 4;
export const PLATFORM_RATING__E = 6;

// visa ==========================================
export const VISA__VISA_SUPPORT_UNKNOWN = 0;
export const VISA__WILL_TRANSFER_H1 = 1;
export const VISA__WILL_SPONSOR_NEW_H1 = 2;
export const VISA__CITIZEN_ONLY = 3;
export const VISA__CITIZEN_AND_GREEN_CARD_ONLY = 4;

// visaCandidate =================================
export const VISA_CANDIDATE__VISA_STATUS_UNKNOWN = 0;
export const VISA_CANDIDATE__CITIZEN = 1;
export const VISA_CANDIDATE__GREEN_CARD = 6;
export const VISA_CANDIDATE__NEEDS_TN_CN_MX = 2;
export const VISA_CANDIDATE__NEEDS_H1B1_E3_CH_SN_AU = 3;
export const VISA_CANDIDATE__NEEDS_H1B_VISA_TRANSFER = 4;
export const VISA_CANDIDATE__NEEDS_NEW_VISA_SPONSOR = 5;
export const VISA_CANDIDATE__NO_SPONSORSHIP_REQUIRED = 8;
export const VISA_CANDIDATE__OPT_CPT_F1 = 9;
export const VISA_CANDIDATE__H1B_WITH_I140_APPROVED = 10;
export const VISA_CANDIDATE__L1 = 11;
export const VISA_CANDIDATE__H4_EAD = 12;
export const VISA_CANDIDATE__J1 = 13;
export const VISA_CANDIDATE__O1 = 14;

// matchType =====================================
export const MATCH_TYPE__REC_SELF_MATCH = 1;
export const MATCH_TYPE__ML_AUTO_CATCH = 2;
export const MATCH_TYPE__ADMIN_ADDITIONAL_MATCH = 3;
export const MATCH_TYPE__ADMIN_BACK_MATCH = 4;

// positiveSignals ===============================
export const POSITIVE_SIGNALS__ACES_CODING_INTERVIEWS = 1;
export const POSITIVE_SIGNALS__WON_AWARD = 2;
export const POSITIVE_SIGNALS__COMPUTER_DEGREE = 3;
export const POSITIVE_SIGNALS__UNDER_REPRESENTED_BACKGROUND = 4;
export const POSITIVE_SIGNALS__EAGLE_SCOUT = 5;
export const POSITIVE_SIGNALS__FOUNDING_TEAM = 6;
export const POSITIVE_SIGNALS__GITHUB = 7;
export const POSITIVE_SIGNALS__HACKATHON = 8;
export const POSITIVE_SIGNALS__HIGH_GPA = 9;
export const POSITIVE_SIGNALS__INTERVIEW_TEAM = 10;
export const POSITIVE_SIGNALS__MENTOR = 11;
export const POSITIVE_SIGNALS__OPEN_SOURCE_PROJECT = 12;
export const POSITIVE_SIGNALS__PATENT = 13;
export const POSITIVE_SIGNALS__PROMOTION = 14;
export const POSITIVE_SIGNALS__PUBLICATIONS = 15;
export const POSITIVE_SIGNALS__STEM_COMPUTER_RELATED_DEGREE = 16;
export const POSITIVE_SIGNALS__SIDE_PROJECT = 17;
export const POSITIVE_SIGNALS__STARTUP_EXPERIENCE = 18;
export const POSITIVE_SIGNALS__STRONG_TECH_COMPANY = 20;
export const POSITIVE_SIGNALS__ELITE_UNIVERSITY = 21;
export const POSITIVE_SIGNALS__RANKED_UNIVERSITY = 22;
export const POSITIVE_SIGNALS__TOP_RANKED_UNIVERSITY = 23;
export const POSITIVE_SIGNALS__TOP_TIER_TECH_COMPANY = 24;
export const POSITIVE_SIGNALS__SCHOLARSHIP = 25;
export const POSITIVE_SIGNALS__STRONG_UNIVERSITY = 26;
export const POSITIVE_SIGNALS__GREAT_GITHUB = 27;
export const POSITIVE_SIGNALS__STRONG_GITHUB = 28;
export const POSITIVE_SIGNALS__IMPRESSIVE_GITHUB = 29;
export const POSITIVE_SIGNALS__HACKATHON_WINNER = 30;
export const POSITIVE_SIGNALS__TECH_COMPANY = 31;
export const POSITIVE_SIGNALS__ACM_COMPETITION = 32;
export const POSITIVE_SIGNALS__FORTUNE_1000 = 33;
export const POSITIVE_SIGNALS__UNICORN_STARTUP = 34;
export const POSITIVE_SIGNALS__FAANG = 35;
export const POSITIVE_SIGNALS__YCOMBINATOR_STARTUP = 36;
export const POSITIVE_SIGNALS__ACQUIRED_STARTUP = 37;
export const POSITIVE_SIGNALS__PUBLIC_COMPANY = 38;
export const POSITIVE_SIGNALS__SMALL_COMPANY = 39;
export const POSITIVE_SIGNALS__TEACH_LEAD = 40;
export const POSITIVE_SIGNALS__PRE_SEED_EXPERIENCE = 41;
export const POSITIVE_SIGNALS__SEED_EXPERIENCE = 42;
export const POSITIVE_SIGNALS__SERIES_A_EXPERIENCE = 43;
export const POSITIVE_SIGNALS__SERIES_B_EXPERIENCE = 44;
export const POSITIVE_SIGNALS__SERIES_C_EXPERIENCE = 45;
export const POSITIVE_SIGNALS__SERIES_D_EXPERIENCE = 46;
export const POSITIVE_SIGNALS__SERIES_E_PLUS_EXPERIENCE = 47;
export const POSITIVE_SIGNALS__TOP_TIER_VC = 48;

// negativeSignals ===============================
export const NEGATIVE_SIGNALS__CODDING_BOOTCAMP = 1;
export const NEGATIVE_SIGNALS__EMPLOYMENT_GAP = 2;
export const NEGATIVE_SIGNALS__JOB_HOPPER = 3;
export const NEGATIVE_SIGNALS__LACKING_4_YEAR_DEGREE = 4;
export const NEGATIVE_SIGNALS__LARGE_SLOW_COMPANY_TYPE = 5;
export const NEGATIVE_SIGNALS__POOR_COMMUNICATION = 7;
export const NEGATIVE_SIGNALS__TYPOS = 8;
export const NEGATIVE_SIGNALS__CONSULTING_BACKGROUND = 9;
export const NEGATIVE_SIGNALS__NON_TECH_COMPANY = 11;

// engagementMatchStrength =======================
export const ENGAGEMENT_MATCH_STRENGTH__STRONG_YES = 1;
export const ENGAGEMENT_MATCH_STRENGTH__YES = 2;
export const ENGAGEMENT_MATCH_STRENGTH__WEAK_YES = 3;
export const ENGAGEMENT_MATCH_STRENGTH__NO = 5;
export const ENGAGEMENT_MATCH_STRENGTH__STRONG_NO = 6;

// diversity =====================================
export const DIVERSITY_CATEGORIES__UNKNOWN = 1;

// accountAction =================================
export const ACCOUNT_ACTION__LIST_CANDIDATES = 1;
export const ACCOUNT_ACTION__LIST_JOBS = 2;
export const ACCOUNT_ACTION__LIST_EMPLOYERS = 3;
export const ACCOUNT_ACTION__LIST_ENGAGEMENTS = 4;
export const ACCOUNT_ACTION__LIST_ACCOUNTS = 5;
export const ACCOUNT_ACTION__VIEW_REPORTS = 6;
export const ACCOUNT_ACTION__VIEW_ANNOUNCEMENTS = 7;
export const ACCOUNT_ACTION__EDIT_ACCOUNT = 8;
export const ACCOUNT_ACTION__EDIT_EMPLOYER = 9;
export const ACCOUNT_ACTION__EDIT_JOB = 10;
export const ACCOUNT_ACTION__EDIT_CANDIDATE = 11;
export const ACCOUNT_ACTION__MATCH_JOB = 12;
export const ACCOUNT_ACTION__MATCH_CANDIDATE = 13;
/**
 * [ 2023-03-03 ][ MS ]
 * We have separated list engagement and view engagement because even is using same component
 * which is Engagements for view engagements is filtering by id and it is because 
 * this view route could be included in specific emails.
 * */
export const ACCOUNT_ACTION__VIEW_ENGAGEMENT = 14;
export const ACCOUNT_ACTION__MANAGE_TAGS = 15;
export const ACCOUNT_ACTION__MANAGE_CONFIGURATIONS = 16;
export const ACCOUNT_ACTION__MANAGE_GOOGLE = 17;
export const ACCOUNT_ACTION__CREATE_ANNOUNCEMENTS = 18;
export const ACCOUNT_ACTION__ACCESS_ADMIN_TOOLS = 19;
export const ACCOUNT_ACTION__EMPLOYER_PENDINGS = 20;
export const ACCOUNT_ACTION__RESUME_SUBMISSION = 21;
export const ACCOUNT_ACTION__EDIT_SUBROLE = 22;
export const ACCOUNT_ACTION__MANAGE_SUBROLES = 23;
export const ACCOUNT_ACTION__CREATE_SYSADMIN_ACCOUNT = 24;
