import moment from 'moment';
import React, { useState } from 'react';
import Candidate from '../../../lib/Candidate';
import Core from '../../../lib/Core';
import { hoverDisplay, mapEngagements } from '../../../lib/models/engagement';
import { COLLECTION__ENGAGEMENTS, readLoopbackRecord } from '../../../lib/services/BE/loopback.api';
import Box from '../../Layout/Wrappers/Box';
import Divider from '../../Layout/Wrappers/Divider';
import Table from '../../Layout/Wrappers/Table';
import Loader from '../../Shared/Loader';

export function JobEngagementsDetails(props) {
  const { job } = props;
  const [loading, setLoading] = useState();
  let [engagements, setEngagements] = useState();
  async function fetchJobEngagements() {
    setLoading(true);
    let _engagements = await readLoopbackRecord({
      collection: COLLECTION__ENGAGEMENTS,
      where: { jobId: job.id },
      fields: ['jobId', 'candidateId', 'lastAction', 'stage', 'status', 'submitted', 'reviewed', 'screen1', 'screen2', 'screen3', 'onsite1', 'onsite2', 'state'],
      include: [
        {
          relation: 'candidate',
          scope: {
            fields: ['id', 'firstName', 'lastName']
          }
        },
        {
          relation: 'job',
          scope: {
            fields: ['id', 'jobTitle', 'roles', 'employerId'],
            include: {
              relation: 'employer',
              scope: {
                fields: ['id', 'name']
              }
            }
          }
        }
      ],
      mapper: mapEngagements,
    });
    job.engagements = _engagements;
    setEngagements(_engagements);
    setTimeout(() => setLoading(false));
  }
  if (!engagements) {
    if (!!job.engagements.length) { setEngagements(job.engagements); }
    else if (!loading) {
      fetchJobEngagements();
    }
  }
  if (Array.isArray(engagements)) {
    engagements = engagements.sort((a, b) => {
      return String(moment(b.lastAction).toISOString()).localeCompare(moment(a.lastAction).toISOString());
    });
  }
  return (
    <>
      <Box row w100 noWrap className='bg-white f-lg p-2'>
        <div className='fw-600 c-cyan-darker mr-1 nowrap'>
          Engagements for job:
        </div>
        <div className='c-black'>
          <did>
            {job._name}
          </did>
          <div>
            {job._roles}
          </div>
        </div>
      </Box>
      <Divider />
      {!!engagements && !!engagements.length ? (
        <>
          <div className="material-table border-bottom">
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Candidate</Table.Cell>
                  <Table.Cell>Last Action</Table.Cell>
                  <Table.Cell className="inline-blocks">
                    Stage&nbsp;
                    <i
                      title="Confirmation - need candidate's approval for submission&#013;Submission - 10x10 processing&#013;Review - employer reviewing resume&#013;Screen - phone screen or homework&#013;Onsite - in person interview&#013;Offer - offer discussion in progress&#013;Hire - offer accepted&#013;Guarantee - employment started"
                      className="material-icons"
                    >
                      info
                    </i>
                  </Table.Cell>
                  <Table.Cell className="inline-blocks">
                    Status&nbsp;
                    <i
                      title="W - waiting on the entity listed to complete the next action to move the interview forward&#013;E - the interview process was ended by the entity listed"
                      className="material-icons"
                    >
                      info
                    </i>
                  </Table.Cell>
                  <Table.Cell>Submitted</Table.Cell>
                  <Table.Cell>CV Review</Table.Cell>
                  <Table.Cell>Screen</Table.Cell>
                  <Table.Cell>Onsite</Table.Cell>
                  <Table.Cell>State</Table.Cell>
                </Table.Row>
                {engagements.map((eng) => (
                  <Table.Row
                    key={Core.getKey()}
                    style={{ opacity: eng.state === 'Open' ? 1 : 0.4 }}
                    title={eng.state === 'Closed' && (eng.rejectionReason ? `Rejection Reason: ${eng.rejectionReason}` : 'Unknown rejection reason')}
                    onClick={(ev) => Core.log({ eng })}
                  >
                    <Table.Cell>
                      <span
                        className="anchor"
                        onClick={(ev) =>
                          Candidate.open('edit', eng.candidateId)
                        }
                      >
                        {eng.candidate._name}
                      </span>
                    </Table.Cell>
                    <Table.Cell title={eng.lastAction}>
                      {eng._lastAction}
                    </Table.Cell>
                    <Table.Cell>{eng.stage}</Table.Cell>
                    <Table.Cell title={hoverDisplay(eng)}>{eng.status}</Table.Cell>
                    <Table.Cell title={eng.submitted}>
                      {eng._submitted}
                    </Table.Cell>
                    <Table.Cell title={eng.reviewed}>
                      {eng._reviewed}
                    </Table.Cell>
                    <Table.Cell>
                      <div title={`Screen 1: ${eng.screen1}`}>
                        {eng._screen1}
                      </div>
                      <div title={`Screen 2: ${eng.screen2}`}>
                        {eng._screen2}
                      </div>
                      <div title={`Screen 3: ${eng.screen3}`}>
                        {eng._screen3}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div title={`Onsite 1: ${eng.onsite1}`}>
                        {eng._onsite1}
                      </div>
                      <div title={`Onsite 2: ${eng.onsite2}`}>
                        {eng._onsite2}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{eng.state}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </>
      ) : (
        <>
          <div className="material-table border-bottom">
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    {loading ? <Loader /> : 'No engagements'}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>
        </>
      )}
    </>
  );
}
