import { cloneDeep } from 'lodash';
import Candidate from './Candidate';
import Core from './Core';
import Employer from './Employer';
import Engagement from './Engagement';
import {
  NOT
} from './GenericTools.lib';
import Http from './Http';
import Job from './Job';
import {
  mapAccount,
  mapAccounts
} from './models/account';
import {
  mapEmployers
} from './models/employer';
import {
  mapJobs
} from './models/job';
import {
  Obj
} from './Object.lib';
import {
  QUERY__ACCOUNT_EMPLOYER__BY_ACCOUNT__WITH_EMPLOYER
} from './queries/AccountEmployer.query';
import {
  QUERY__EMPLOYER__SIMPLE_SINGLE
} from './queries/EmployerSimpleSingle.query';
import {
  COLLECTION__ACCOUNT_EMPLOYERS,
  COLLECTION__ACCOUNTS,
  COLLECTION__EMPLOYERS,
  createLoopbackRecord,
  deleteLoopbackRecord,
  readLoopbackRecord,
  updateLoopbackRecord
} from './services/BE/loopback.api';
import { compileText } from './String.lib';

const cache = {};
const commonQuery = {
  include: [
    {
      relation: 'accountStarreds',
      scope: {
        where: {
          userId: Core.getUserId(),
        },
      },
    },
  ],
};

const menus = [];
const more = [{ label: 'State', key: 'state' }];
const listTabs = ['Name', 'Role', 'Recent', 'Starred'];
const listTab = 'Name';

const Account = {
  name: 'Account',
  columns: [
    {
      headers: [
        {
          label: 'Account',
          key: '_infoCmp',
          sortKey: '_name',
          filter: true,
        },
        {
          label: 'Role',
          key: 'role',
        },
        {
          label: 'Engagements',
          key: '_engagementsCmp',
          sortKey: '_engagementsLength',
          reverseSort: true,
        },
      ],
      selected: 0,
      style: { minWidth: 300 },
    },
    {
      headers: [{ label: 'Company', key: 'companyName' }],
      selected: 0,
    },
    {
      headers: [{ label: 'Title', key: 'title' }],
      selected: 0,
    },
    {
      headers: [{ label: 'Email', key: '_emailCmp', sortKey: 'email' }],
      selected: 0,
    },
    {
      headers: [{ label: 'Phone', key: 'phone' }],
      selected: 0,
    },
    {
      headers: [
        {
          label: 'Starred',
          key: '_rowOptionsCmp',
          sortKey: '_starred',
          reverseSort: true,
          hint: 'Options',
        },
      ],
      selected: 0,
      style: { width: 124, textAlign: 'right' },
    },
  ],
  cleanCache: (em) =>
    Object.keys(cache).forEach((key) => {
      delete cache[key];
    }),
  getActives: (success) => {
    success = success instanceof Function ? success : function () { };
    if (cache.actives) {
      setTimeout((st) => success(cache.actives));
    } else {
      Http.get(
        Core.getApi('Accounts'),
        {
          filter: JSON.stringify({ ...commonQuery, where: { state: 1 } }),
        },
        function onSuccess(response) {
          cache.actives = mapAccounts(response);
          success(cache.actives);
        }
      );
    }
  },
  getAll: (cb, opts = {}) => {
    let newCommonQuery = [...commonQuery.include];
    if (!!Object.keys(opts).length) {
      newCommonQuery = [...newCommonQuery, ...opts.include];
    }

    cb = cb instanceof Function ? cb : function () { };
    if (cache.all) {
      setTimeout((st) => cb(cache.all));
    } else {
      Http.get(
        Core.getApi('Accounts'),
        {
          filter: JSON.stringify({ include: [...newCommonQuery] }),
        },
        function onSuccess(response) {
          cache.all = mapAccounts(response);
          cb(cache.all);
        }
      );
    }
  },
  getNames: (success) => {
    Http.get(
      Core.getApi('Accounts'),
      {
        filter: JSON.stringify({
          fields: { id: true, firstName: true, lastName: true },
        }),
      },
      (response) => success(response)
    );
  },
  getWhere: async (where, cb) => {
    cb = cb instanceof Function ? cb : function () { };
    const key = JSON.stringify(where).replace(/\W/g, '');
    if (cache[key]) {
      setTimeout((st) => cb(cache[key]));
      return cache[key];
    } else {
      return Http.get(
        Core.getApi('Accounts'),
        {
          filter: JSON.stringify({ ...commonQuery, where: { ...where } }),
        },
        function onSuccess(response) {
          cache[key] = mapAccounts(response);
          cb(cache[key]);
        }
      ).then(mapAccounts);
    }
  },
  get: async (accountId, cb) => {
    cb = cb instanceof Function ? cb : function () { };
    if (cache[accountId]) {
      setTimeout((st) => cb(cache[accountId]));
      return cache[accountId];
    } else {
      return Http.get(
        Core.getApi('Accounts/' + accountId),
        {
          filter: JSON.stringify({ ...commonQuery }),
        },
        function onSuccess(response) {
          cache[accountId] = mapAccount(response);
          cb(cache[accountId]);
        }
      ).then(mapAccount);
    }
  },
  updateStarred: (accountId, starredId, starred, success) => {
    Account.cleanCache();
    if (starredId) {
      return Http.patch(
        Core.getApi('AccountStarreds/' + starredId),
        { starred },
        success
      );
    } else {
      return Http.post(
        Core.getApi('AccountStarreds'),
        {
          accountId,
          starred,
          userId: Core.getUserId(),
        },
        success
      );
    }
  },
  post: async (account, roleId, lists, success = () => null) => {
    Account.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    return Http.post(Core.getApi('Accounts'), account, function onSuccess(response) {
      if (roleId) {
        Account.postRoleMap(response.id, roleId, (roleMap) => {
          success(response);
          Account.updateEmployerBlackList(
            response.id,
            lists['accountEmployerBlackList']
          );
          Account.updateRecruiterWhiteList(
            response.id,
            lists['accountRecruiterWhiteList']
          );
          Account.updateJobBlackList(response.id, lists['accountJobBlackList']);
        });
      } else {
        success(response);
        Account.updateEmployerBlackList(
          response.id,
          lists['accountEmployerBlackList']
        );
        Account.updateRecruiterWhiteList(
          response.id,
          lists['accountRecruiterWhiteList']
        );
        Account.updateJobBlackList(response.id, lists['accountJobBlackList']);
      }
    });
  },
  update: async (accountId, account, roleMapId, roleId, lists, success = () => null) => {
    Account.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    return Http.patch(
      Core.getApi('Accounts/' + accountId),
      account,
      function onSuccess(response) {
        if (roleMapId && roleId) {
          Account.updateRoleMap(roleMapId, roleId, (roleMap) => {
            success(response);
            Account.updateRecruiterWhiteList(
              accountId,
              lists['accountRecruiterWhiteList']
            );
            Account.updateEmployerBlackList(
              accountId,
              lists['accountEmployerBlackList']
            );
            Account.updateJobBlackList(accountId, lists['accountJobBlackList']);
            Account.updateEmployerSourceList(
              accountId,
              lists['accountEmployerSourceList']
            );
            Account.updateJobSourceList(
              accountId,
              lists['accountJobSourceList']
            );
          });
        } else if (roleId) {
          Account.postRoleMap(response.id, roleId, (roleMap) => {
            success(response);
            Account.updateRecruiterWhiteList(
              accountId,
              lists['accountRecruiterWhiteList']
            );
            Account.updateEmployerBlackList(
              accountId,
              lists['accountEmployerBlackList']
            );
            Account.updateJobBlackList(accountId, lists['accountJobBlackList']);
            Account.updateEmployerSourceList(
              accountId,
              lists['accountEmployerSourceList']
            );
            Account.updateJobSourceList(
              accountId,
              lists['accountJobSourceList']
            );
          });
        } else {
          success(response);
        }
      }
    );
  },
  changePassword({ oldPassword, newPassword }, success, failure) {
    Http.post(
      Core.getApi('Accounts/change-password'),
      { oldPassword, newPassword },
      success,
      failure
    );
  },
  /** ACCOUNT ROLE MAPPING */
  postRoleMap: (accountId, roleId, success) => {
    Account.cleanCache();
    Http.post(
      Core.getApi('RoleMappings'),
      {
        principalType: 'USER',
        principalId: accountId,
        roleId: roleId,
      },
      success
    );
  },
  updateRoleMap: (roleMapId, roleId, success) => {
    Account.cleanCache();
    Engagement.cleanCache();
    Candidate.cleanCache();
    Employer.cleanCache();
    Http.patch(Core.getApi('RoleMappings/' + roleMapId), { roleId }, success);
  },
  getRoleMapping: (accountId, success) => {
    Http.get(
      Core.getApi('RoleMappings'),
      {
        filter: JSON.stringify({
          include: 'role',
          where: { principalId: accountId },
          limit: 1,
        }),
      },
      (response) => success(response[0])
    );
  },
  /** ACCOUNT WHITE LIST EMPLOYERS */
  getRecruiterWhiteList: (accountId, success) => {
    return Http.get(
      Core.getApi(`Accounts/${accountId}/recruiterWhiteList`),
      function onSuccess(response) {
        success && success(mapEmployers(response));
      }
    );
  },
  updateRecruiterWhiteList: (accountId, list, success) => {
    Account.cleanCache();
    Http.delete(
      Core.getApi(`Accounts/${accountId}/recruiterWhiteList`),
      function onSuccess(response) {
        list.map((item) => Account.postRecruiterWhiteList(accountId, item.id));
      }
    );
  },
  postRecruiterWhiteList: (accountId, employerId) => {
    Account.cleanCache();
    Http.post(Core.getApi(`RecruiterWhiteLists`), {
      accountId,
      employerId,
    });
  },
  /** ACCOUNT BLACK LIST EMPLOYERS */
  getEmployerBlackList: (accountId, success) => {
    Http.get(
      Core.getApi(`Accounts/${accountId}/employerBlackList`),
      function onSuccess(response) {
        success(mapEmployers(response));
      }
    );
  },
  updateEmployerBlackList: (accountId, list, success) => {
    Account.cleanCache();
    Employer.cleanCache();
    Http.delete(
      Core.getApi(`Accounts/${accountId}/employerBlackList`),
      function onSuccess(response) {
        list.map((item) => Account.postEmployerBlackItem(accountId, item.id));
      }
    );
  },
  postEmployerBlackItem: (accountId, employerId) => {
    Account.cleanCache();
    Employer.cleanCache();
    Http.post(Core.getApi(`EmployerBlackLists`), {
      accountId,
      employerId,
    });
  },
  /** ACCOUNT BLACK LIST JOBS */
  getJobBlackList: (accountId, success) => {
    Http.get(
      Core.getApi(`Accounts/${accountId}/jobBlackList`),
      {
        filter: JSON.stringify({
          include: [{ relation: 'employer', scope: { fields: ['name'] } }],
          fields: ['id', 'jobTitle', 'employerId', 'employer'],
        }),
      },
      function onSuccess(response) {
        success(mapJobs(response));
      }
    );
  },
  updateJobBlackList: (accountId, list, success) => {
    Account.cleanCache();
    Job.cleanCache();
    Http.delete(
      Core.getApi(`Accounts/${accountId}/jobBlackList`),
      function onSuccess(response) {
        list.map((item) => Account.postJobBlackList(accountId, item.id));
      }
    );
  },
  postJobBlackList: (accountId, jobId) => {
    Account.cleanCache();
    Job.cleanCache();
    Http.post(Core.getApi(`JobBlackLists`), {
      accountId,
      jobId,
    });
  },
  /** ACCOUNT SOURCE LIST EMPLOYERS */
  getEmployerSourceList: (accountId, success) => {
    Http.get(
      Core.getApi(`Accounts/${accountId}/employerSourceList`),
      function onSuccess(response) {
        success(mapEmployers(response));
      }
    );
  },
  updateEmployerSourceList: (accountId, list, success) => {
    Account.cleanCache();
    Employer.cleanCache();
    list.forEach((item) => Account.postEmployerSourceItem(accountId, item.id));
  },
  postEmployerSourceItem: (accountId, employerId) => {
    Account.cleanCache();
    Employer.cleanCache();
    Http.post(
      Core.getApi(
        'EmployerSourceLists/upsertWithWhere?where=' +
        JSON.stringify({ accountId, employerId })
          .replace('{', '%7B')
          .replace('}', '%7D')
          .replace(':', '%3A')
      ),
      {
        accountId,
        employerId,
        startDate: new Date(),
        who: Core.getSessionEmail(),
      }
    );
  },
  /** ACCOUNT SOURCE LIST JOBS */
  getJobSourceList: (accountId, success) => {
    Http.get(
      Core.getApi(`Accounts/${accountId}/jobSourceList`),
      {
        filter: JSON.stringify({
          include: [
            { relation: 'employer', scope: { fields: ['name'] } },
            { relation: 'jobSourceList', scope: { fields: ['startDate'] } },
          ],
          fields: [
            'id',
            'jobTitle',
            'employerId',
            'employer',
            'jobSourceListId',
            'jobSourceList',
            'state',
          ],
        }),
      },
      function onSuccess(response) {
        success(mapJobs(response));
      }
    );
  },
  updateJobSourceList: (accountId, list, success) => {
    Account.cleanCache();
    Job.cleanCache();
    list.forEach((item) => Account.postJobSourceList(accountId, item.id));
  },
  postJobSourceList: (accountId, jobId) => {
    Account.cleanCache();
    Job.cleanCache();
    Http.post(
      Core.getApi(
        'JobSourceLists/upsertWithWhere?where=' +
        JSON.stringify({ accountId, jobId })
          .replace('{', '%7B')
          .replace('}', '%7D')
          .replace(':', '%3A')
      ),
      { accountId, jobId, startDate: new Date(), who: Core.getSessionEmail() }
    );
  },
  /** ACCOUNT OTHERS */
  getRoles: (success) => {
    Http.get(Core.getApi('Roles'), success);
  },
  getRecruiters: (success) => {
    Http.get(Core.getApi('Accounts/recruiters'), (response) =>
      success(mapAccounts(response))
    );
  },
};

const AccountLib = {
  menus,
  more,
  listTabs,
  listTab,
  ...Account,
};

export default AccountLib;

/**
 * 
 * @param {object} options
 * @param {string} options.accountId  [required]
 * @param {string} options.employerId  [optional] IF supplied, a new relation will be CREATED.
 * 
 * @returns {object} Employer from AccountEmployer relation.
 */
export async function getAccountEmployer({ accountId, employerId }) {

  if (NOT(accountId)) { return Core.showError(`Account id is required!`); }

  /**
   * GET existing relation by accountId.
   */
  let accountEmployer = Obj(
    await readLoopbackRecord({
      collection: COLLECTION__ACCOUNT_EMPLOYERS,
      ...compileText(cloneDeep(QUERY__ACCOUNT_EMPLOYER__BY_ACCOUNT__WITH_EMPLOYER), {
        ACCOUNT__ID: accountId
      })
    })
  );

  /**
   * IF employerId was supplied AND current relation HAS diff employerId,
   * THEN DELETE current relation AND CREATE a new one.
   */
  if (employerId && (accountEmployer.employerId !== employerId)) {
    await deleteLoopbackRecord({
      collection: COLLECTION__ACCOUNT_EMPLOYERS,
      id: accountEmployer.id
    });
    accountEmployer = Obj(
      await createLoopbackRecord({
        collection: COLLECTION__ACCOUNT_EMPLOYERS,
        record: { accountId, employerId }
      })
    );
    if (accountEmployer.id) {
      accountEmployer.employer = Obj(
        await readLoopbackRecord({
          collection: `${COLLECTION__EMPLOYERS}/${accountEmployer.employerId}`,
          ...QUERY__EMPLOYER__SIMPLE_SINGLE
        })
      );
    }
    await updateLoopbackRecord({
      collection: COLLECTION__ACCOUNTS,
      id: accountEmployer.accountId,
      record: { companyName: accountEmployer.employer.name }
    });
  }

  return accountEmployer.employer;
}
