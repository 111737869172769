import {
  compile
} from 'handlebars';
import moment from 'moment';
import React from "react";
import {
  useTranslation
} from "react-i18next";
import {
  TEN_BY_TEN_ADMIN_CONTACT,
  TIMEZONE__LA
} from '../../lib/Constants';
import Core from "../../lib/Core";
import {
  getPacificTimeIsoString,
  isValidDate
} from '../../lib/Date.lib';
import {
  ACCOUNT_ACTION__EDIT_CANDIDATE
} from '../../lib/Definition';
import Engagement from '../../lib/Engagement';
import {
  copyString
} from "../../lib/GenericTools.lib";
import {
  ENGAGEMENT__STATE_OPEN,
  REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
  STAGE_CONFIRMATION,
  STATUS_E_RECRUITER
} from '../../dictionaries/Engagement.dic';
import {
  getLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import {
  mapContactsToStrings,
  sendSafeEmail
} from '../../lib/services/Email/Email.lib';
import {
  RECRUITER_WITHDRAW_CANDIDATE__BODY,
  RECRUITER_WITHDRAW_CANDIDATE__SUBJECT
} from '../../lib/templates/RecruiterWithdrawCandidate.template';
import downloadFile from "../../lib/tools/downloadFile";
import MatchExclusionFieldset from '../Candidates/Forms/MatchExclusionFieldset';
import {
  cancelConfirm,
  showConfirm
} from '../Dialogs/AppConfirmationDialog';
import Button from '../Layout/Wrappers/Button';
import Gravatar from '../Layout/Wrappers/Gravatar';
import IconButton from '../Layout/Wrappers/IconButton';
import NavLink from '../Layout/Wrappers/NavLink';

export function CandidateNameHeader(props) {
  const { t } = useTranslation();
  return (
    <>
      {t('Name')}
    </>
  );
}

export function CandidateNameCellForReactTable(props) {
  let {
    row: {
      original: candidate = {}
    },
  } = props;
  return (
    <CandidateNameCell candidate={candidate} />
  );
}

export function CandidateNameCell({ candidate }) {
  return (
    (candidate.___model___ === 'Candidate')
      ? (
        <div className="d-flex flex-align-left-center flex-wrap">
          <div>
            {
              !Core.isAdmin() || Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE })
                ?
                (
                  <NavLink
                    title="Go to Edit"
                    to={"/candidate/edit/" + candidate.id}
                    target={
                      candidate.isDuplicate
                        ? '_blank'
                        : ''
                    }
                    className='mr-1'
                  >
                    <b>{candidate._name_rating || <i>&mdash;</i>}</b>
                    {candidate._isDraft && <div style={{ color: 'red !important' }}>Draft - not submitted yet</div>}
                  </NavLink>
                )
                :
                (
                  <>
                    <b>{candidate._name_rating || <i>&mdash;</i>}</b>
                    {candidate._isDraft && <div style={{ color: 'red !important' }}>Draft - not submitted yet</div>}
                  </>
                )
            }
            <div className="d-flex flex-align-left-center flex-wrap">
              <IconButton
                title={"Copy Candidate email"}
                icon='email'
                iconStyle={candidate.openedColor}
                className="icon16 mr-1"
                onClick={ev => copyString(candidate.email)}
              />
              {!!candidate.phone &&
                <IconButton
                  title="Copy Candidate phone"
                  icon='phone'
                  iconStyle={candidate.openedColor}
                  className="icon16 mr-1"
                  onClick={ev => copyString(candidate.phone)}
                />
              }
              {!!candidate.resumes.length && (
                (resume) => {
                  return (
                    <IconButton
                      title='Download resume'
                      className="icon16 mr-1"
                      onClick={ev =>
                        downloadFile({
                          url: resume.url,
                          mimeType: "application/pdf",
                          onError: error => {
                            Core.failure({ title: 'Download File', exception: error, source: 'CellCandidateNameColumn.jsx', params: { url: resume.url } });
                          }
                        })
                      }
                    >
                      <i className="material-icons">file_download</i>
                    </IconButton>
                  );
                })(candidate.resumes[0])
              }
              <IconButton
                title='Withdraw candidate'
                onClick={event => {
                  event.stopPropagation();
                  showConfirm({
                    title: (
                      <div className='d-flex flex-align-left-center'>
                        <span className='mr-auto c-cyan-darker'>
                          Withdraw candidate
                        </span>
                        <IconButton onClick={cancelConfirm}>
                          <i className='material-icons'>close</i>
                        </IconButton>
                      </div>
                    ),
                    message: (
                      <MatchExclusionFieldset candidateId={candidate.id} />
                    ),
                    displayActions: false,
                    actionOptions: [
                      {
                        variant: 'contained',
                        color: 'primary',
                        label: 'Close'
                      }
                    ],
                    async onCancel() {
                      let candidateOpenEngagements = await getLoopbackRecord({
                        collection: 'Engagements',
                        where: {
                          candidateId: candidate.id,
                          state: ENGAGEMENT__STATE_OPEN
                        }
                      });
                      let candidateConfirmationEngagements = candidateOpenEngagements.filter(({ stage }) => stage === STAGE_CONFIRMATION);
                      let note = Core.getKeyValue('MatchExclusionFieldSet__note');
                      let reasons = Core.getKeyValue('MatchExclusionFieldSet__reasons');
                      let excludeUntil = Core.getKeyValue('MatchExclusionFieldSet__excludeUntil');
                      let isCurrentExcludeUntil = excludeUntil ? moment().isBefore(excludeUntil) : false;
                      // console.debug(candidateConfirmationEngagements);
                      if (isCurrentExcludeUntil) {

                        Core.showLoader('Withdrawing candidate..., please do not close the window');

                        // Sending email
                        let subject = compile(RECRUITER_WITHDRAW_CANDIDATE__SUBJECT)({
                          recruiterFullName: candidate.recruiter._name,
                          candidateFullName: candidate._name,
                          todayPT: moment().tz(TIMEZONE__LA).format('MM/DD HH:mm z')
                        }).replace('[', '{').replace(']', '}');
                        let body = compile(RECRUITER_WITHDRAW_CANDIDATE__BODY)({
                          candidateFullName: candidate._name,
                          withdrawReasons: Array.isArray(reasons) && !!reasons.length ? reasons.join(', ') : 'Nothing selected',
                          withdrawExcludeUntil: isValidDate(excludeUntil) ? moment(excludeUntil).format('YYYY-MM-DD') : '',
                          withdrawNote: note ? '<br />' + note.replace(/\n/gi, '<br />').trim() : 'Nothing typed',
                        });
                        await sendSafeEmail({
                          from: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
                          to: mapContactsToStrings([TEN_BY_TEN_ADMIN_CONTACT]),
                          subject,
                          html: body
                        });

                        // Closing engagements
                        for (let engagement of candidateConfirmationEngagements) {
                          console.debug({ engagement, candidate, reasons, excludeUntil, note });
                          await Engagement.close({
                            engagement,
                            status: STATUS_E_RECRUITER,
                            rejectionReason: REJECTION_REASON__CANDIDATE__NOT_INTERESTED,
                            rejectionReasonAdditionalInfo: note,
                            confirmed: getPacificTimeIsoString()
                          }).catch(console.debug);
                        }
                        if (!!candidateConfirmationEngagements.length) {
                          Core.showMessage(
                            <div className='d-flex flex-align-left-center'>
                              <Button
                                variant='outlined'
                                size='small'
                                onClick={Core.refresh}
                                className='mr-1'
                              >
                                Refresh
                              </Button>
                              <span>
                                to see updated candidate status
                              </span>
                            </div>
                          );
                        }
                        else {
                          Core.showSuccess(
                            <div className='d-flex flex-align-left-center'>
                              Done
                            </div>
                          );
                        }
                      }
                    }
                  });
                }}
                className='tt-unset c-black-medium icon16 mr-2'
                icon='remove_done'
              />
            </div>
          </div>
          <Gravatar
            label={candidate._name}
            email={candidate.email}
            className='mr-1 bg-green-lighter c-cyan-darker'
            hideOnError
          />
        </div>
      )
      : null
  );
}
