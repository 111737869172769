import Core from "../Core";

export function mapStarred({ model, starredList = [] }) {
  model.starred = starredList
    .filter(({ accountId }) => (accountId === Core.getUserId()))
    .sort((a, b) => String(a.updatedAt).localeCompare(b.updatedAt));
  model.starred = model.starred.length ? model.starred.pop() : {};
  model.starredId = model.starred.id || model.starred._id;
  model.starred = model.starred.starred;
  return model;
}
