import Core from '../../Core';
import Http from '../../Http';
import {
  getStateModel
} from '../../models/Models.lib';
import {
  isObject
} from '../../Object.lib';
import {
  isNonEmptyString
} from '../../String.lib';

export const COLLECTION__ACCOUNT_EMPLOYERS = 'AccountEmployers';
export const COLLECTION__ACCOUNTS = 'Accounts';
export const COLLECTION__ACCOUNT_SUBROLES = 'AccountSubroles';
export const COLLECTION__CANDIDATES = 'Candidates';
export const COLLECTION__EMPLOYER_ATS_MAPPING_RULES = 'EmployerAtsMappingRules';
export const COLLECTION__EMPLOYER_EMAIL_TEMPLATES = 'EmployerEmailTemplates';
export const COLLECTION__EMPLOYERS = 'Employers';
export const COLLECTION__EMPLOYERS_BLACK_LISTS = 'EmployerBlackLists';
export const COLLECTION__ENGAGEMENTS = 'Engagements';
export const COLLECTION__JOBS = 'Jobs';
export const COLLECTION__MATCH_DECISION_TABLES = 'MatchDecisionTables';
export const COLLECTION__PRESCREEN_QUESTION_TEMPLATES = 'PrescreenQuestionTemplates';
export const COLLECTION__RECRUITER_WHITE_LISTS = 'RecruiterWhiteLists';
export const COLLECTION__SOVREN_DATA = 'SovrenData';
export const COLLECTION__TEMPLATES = 'Templates';

/**
 * CREATE
 * @param {object} options
 * @param {string} options.collection -table name
 * @param {string} options.model      -model base, if set then it will map the record using model fields
 * @param {string} options.record     -record to be post in the DB
 * @param {function} options.mapper   -routine to do some mapping operation to the results
 */
export async function createLoopbackRecord(options) {
  let { collection, model, record, mapper } = options;
  if (
    !isNonEmptyString(collection) ||
    !isObject(record)
  ) { return null; }
  if (isObject(model)) {
    record = getStateModel(record, model)
  }
  return Http.post(Core.getApi(collection), record).then((response) => {
    if (mapper instanceof Function) {
      response = mapper(response);
    }
    return response;
  });
}

/**
 * READ
 * @param {object} options
 * @param {string} options.collection -table name
 * @param {object} options.where      -where query in loopback format
 * @param {string[]} options.fields   -array of fields
 * @param {number} options.limit      -if 1 resulted returns object else array
 * @param {string} options.order      -order query in loopback format
 * @param {string} options.include    -include query in loopback format
 * @param {function} options.mapper   -routine to do some mapping operation to the results
 */
export async function readLoopbackRecord(options) {
  let { collection, where, fields, limit, order, include, mapper } = options;
  if (!String(collection || '').trim()) { return null; }
  where = Object(where);
  return Http.get(Core.getApi(collection), {
    filter: JSON.stringify({ where, fields, limit, order, include }),
  }).then((response) => {
    if (limit === 1) {
      if (Array.isArray(response)) { response = response[0]; }
    }
    if (mapper instanceof Function) {
      response = mapper(response);
    }
    return response;
  });
}
export const getLoopbackRecord = readLoopbackRecord;

/**
 * UPDATE
 * @param {object} options
 * @param {string} options.collection -table name
 * @param {string} options.id         -record id
 * @param {string} options.model      -model base, if set then it will map the record using model fields
 * @param {string} options.record     -record to be post in the DB
 * @param {function} options.mapper   -routine to do some mapping operation to the results
 */
export async function updateLoopbackRecord(options) {
  let { collection, id, model, record, mapper } = options;
  if (
    !isNonEmptyString(collection) ||
    !isObject(record)
  ) { return null; }
  if (isObject(model)) {
    record = getStateModel(record, model)
  }
  return Http.patch(Core.getApi(`${collection}/${id}`), record).then((response) => {
    if (mapper instanceof Function) {
      response = mapper(response);
    }
    return response;
  });
}


/**
 * DELETE
 * @param {object} options
 * @param {string} options.collection -table name
 * @param {string} options.id         -record id
 * @param {function} options.mapper   -routine to do some mapping operation to the results
 */
export async function deleteLoopbackRecord(options) {
  let { collection, id, mapper } = options;
  if (
    !isNonEmptyString(id)
  ) { return null; }
  return Http.delete(Core.getApi(`${collection}/${id}`)).then((response) => {
    if (mapper instanceof Function) {
      response = mapper(response);
    }
    return response;
  });
}
