import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import {
  Component
} from "react";
import Account from "../../lib/Account";
import {
  Arr
} from '../../lib/Array.lib';
import {
  NOT
} from '../../lib/Boolean.lib';
import Core from "../../lib/Core";
import {
  DATE_FORMAT__DATE__ISO,
  formatDate,
  formatDateTime24hPT,
  TIMEZONE__LA
} from '../../lib/Date.lib';
import {
  ACCOUNT_ACTION__EDIT_ACCOUNT,
  ACCOUNT_ACTION__EDIT_CANDIDATE,
  ACCOUNT_ACTION__LIST_JOBS
} from '../../lib/Definition';
import Employer from "../../lib/Employer";
import Engagement from "../../lib/Engagement";
import {
  getPersonName
} from '../../lib/Object.lib';
import QUERY__REPORTS__ENGAGEMENTS from '../../lib/queries/ReportsEngagements.query';
import {
  readLoopbackRecord
} from '../../lib/services/BE/loopback.api';
import Store from "../../lib/Store";
import {
  joinKeyName
} from '../../lib/String.lib';
import onReady from "../../lib/tools/onReady";
import {
  getLocation
} from '../../lib/URL.lib';
import {
  joinClassName
} from '../Layout/Libraries/Theme.lib';
import Box from '../Layout/Wrappers/Box';
import Button from '../Layout/Wrappers/Button';
import DatePicker from '../Layout/Wrappers/DatePicker';
import Dialog from '../Layout/Wrappers/Dialog';
import Divider from '../Layout/Wrappers/Divider';
import Icon from '../Layout/Wrappers/Icon';
import IconButton from '../Layout/Wrappers/IconButton';
import Menu from '../Layout/Wrappers/Menu';
import Navigate from '../Layout/Wrappers/Navigate';
import Paper from '../Layout/Wrappers/Paper';
import Snackbar from '../Layout/Wrappers/Snackbar';
import {
  PLACEMENT__TOP_START
} from '../Layout/Wrappers/StyledTooltip';
import Table from '../Layout/Wrappers/Table';
import Typography from '../Layout/Wrappers/Typography';

function sortByGroup(a, b) {
  const parseDate = (input) => {
    if (input.startsWith("W")) {
      return moment(input, "[W]WW/YY").isValid()
        ? moment(input, "[W]WW/YY").format("YYYY-[W]WW")
        : null;
    } else {
      return moment(input, "MMM/YY").isValid()
        ? moment(input, "MMM/YY").format("YYYY-MM")
        : null;
    }
  };
  const aDate = parseDate(a.group);
  const bDate = parseDate(b.group);
  if (aDate && bDate) {
    return moment(aDate).diff(moment(bDate));
  }
  else if (aDate) {
    return -1;
  }
  else if (bDate) {
    return 1;
  }
  else {
    return 0;
  }
}

export default class Reports extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      linked: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      snackBarOpen: false,
      snackBarMessage: "...",
      engagementsLoading: false,
      open: false,
      scroll: 'paper',
      clickedCountEngagements: [],
      recruiters: [],
      employers: [],
      recruiter: {},
      employer: {},
      duration: "Monthly",
      viewBy: "Engagements",
      startDate: moment()
        .tz("America/Los_Angeles")
        .startOf("Day")
        .subtract(15, "months")
        .startOf("month")
        .toISOString(),
      endDate: moment()
        .tz("America/Los_Angeles")
        .endOf("day")
        .toISOString()
    };
    Store.set("path", getLocation());
    onReady(this, "rowsContainer").then(em => {
      Account.getActives(recruiters => {
        recruiters = [
          {
            id: "0",
            name: "All"
          },
          ...recruiters
        ]; // Add "All" option
        Employer.getActives(employers => {
          employers = [
            {
              id: "0",
              name: "All"
            },
            ...employers
          ]; // Add "All" option
          this.setState(
            { accountId: "0", recruiters, employerId: "0", employers },
            then => {
              this.getData();
            }
          );
        });
      });
    });
  };

  handleCountClicked = (scroll, val, dateAttr, stage = []) => async (event) => {
    this.setState({ engagementsLoading: true });
    this.setState({ open: true, scroll });

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let month = monthNames.indexOf(val.split('/')[0]);
    let year = +2000 + +val.split('/')[1];
    let startMonth = moment
      .tz([year, month], TIMEZONE__LA)
      .startOf("Day")
      .startOf("month")
      .toISOString();

    let endMonth = moment
      .tz([year, month], TIMEZONE__LA)
      .startOf("Day")
      .endOf("month")
      .toISOString();

    let and1 = {};
    and1[dateAttr] = { neq: null };
    let and2 = {};
    and2[dateAttr] = { gte: startMonth };
    let and3 = {};
    and3[dateAttr] = { lte: endMonth };

    let condCombined = [and1, and2, and3];

    if (stage.length > 0) {
      condCombined.push({ "or": stage });
    }

    try {
      this.setState({
        clickedCountEngagements: await readLoopbackRecord({
          ...QUERY__REPORTS__ENGAGEMENTS,
          where: { and: condCombined },
          mapper: (engagements) => {
            return Arr(engagements).map((engagement) => {
              return {
                URL__CANDIDATE__EDIT: `/candidate/edit/${engagement.candidateId}`,
                URL__ENGAGEMENT__VIEW: `/engagement/view/${engagement.id}`,
                URL__EMPLOYER__EDIT: `/employer/edit/${engagement.job.employerId}`,
                URL__JOB__VIEW: `/job/view/${engagement.jobId}`,
                URL__RECRUITER__EDIT: `/account/edit/${engagement.candidate.accountId}`,
                CANDIDATE__FULLNAME: getPersonName(engagement.candidate),
                EMPLOYER__NAME: engagement.job.employer.name,
                JOB__JOB_TITLE: engagement.job.jobTitle,
                RECRUITER__NAME: getPersonName(engagement.candidate.account),
                INTRODUCED_DATE: formatDate(engagement.introduced),
                INTRODUCED_DATE_24H_PT: formatDateTime24hPT(engagement.introduced),
              }
            });
          }
        }),
        engagementsLoading: false
      });

    }
    catch (error) {
      Core.showError(error);
    }

  };

  handleClose = () => {
    this.setState({ open: false });
  };

  showMessage = msg => {
    this.setState({
      snackBarMessage: msg,
      snackBarOpen: true
    });
  };
  hideMessage = () => {
    this.setState({
      snackBarMessage: "",
      snackBarOpen: false
    });
  };
  getData = event => {
    let params = {
      recruiterId: this.state.accountId,
      employerId: this.state.employerId,
      duration: this.state.duration,
      viewBy: this.state.viewBy,
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };
    Engagement.newPipelineReport(
      params,
      response => {
        this.setRows(
          response.map(
            (row) => ({
              ...row,
              cols: row.cols.sort(sortByGroup)
            })
          )
        );
      },
      error => {
        Core.showFailure("Unable to retrieve new pipeline data: " + error);
      }
    );
  };
  setRows = data => {
    /*
    [
      {
        pipeline: "New Submission",
          cols: [
            { group: "1/14-2/14", value: 40 },
            { group: "2/14-3/14", value: 24 },
            { group: "3/14-4/14", value: 34 },
            { group: "3/14-5/14", value: 45 }
          ]
      }, ...
    ]*/
    const rows = [];
    if (data[0]) {
      let myPaddingStyle = {
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center'
      }
      /** TABLE HEADERS */
      rows.push(
        <Table.Row
          key={Core.getKey()}
        >
          <Table.Cell
            className='min-w-160 sticky-start'
          />
          {data[0].cols.map((col, index) => (
            <Table.Cell
              key={Core.getKey()}
              style={myPaddingStyle}
              className={joinClassName([
                'fw-600 min-w-80 p-1 bg-cyan-common c-white',
                index === data[0].cols.length - 1 ? 'sticky-end' : ''
              ])}
            >
              {col.group}
            </Table.Cell>
          ))}
        </Table.Row>
      );

      let that = this;
      /** TABLE BODY */
      data.forEach((item, rowIndex) => {
        rows.push(
          <Table.Row key={`reports__data__row__${rowIndex}`}>
            <Table.Cell className="fw-600 p-1 align-left sticky-start">
              {item.pipeline}
            </Table.Cell>
            {item.cols.map((col, colIndex) => (
              <Table.Cell
                key={`reports__data__row__${rowIndex}__col__${colIndex}`}
                className={joinClassName([
                  'min-w-80 p-1 align-center',
                  colIndex === data[0].cols.length - 1 ? 'sticky-end fw-600' : ''
                ])}
              >
                <span
                  onClick={that.handleCountClicked('paper', col.group, col.dateAttr, col.stage)}
                  className='cursor-pointer'
                >
                  {col.value}
                </span>
              </Table.Cell>

            ), this)}
          </Table.Row>
        );
      });
      this.setState({ rows });
    }
  };
  render() {
    if (Core.isLoggedOut()) {
      return <Navigate to="/login" />;
    }
    return (
      <Box className="main-padding">
        <h3>New Pipeline</h3>
        <Paper className="main-padding">
          <Snackbar
            open={this.state.snackBarOpen}
            message={this.state.snackBarMessage}
            className="snack-bar"
            autoHideDuration={4000}
            onClose={this.hideMessage}
          />
          <Box column w100 scrollX>
            <Table styled className="collapsed-view">
              <Table.Body>
                <Table.Row className="v-align-top">
                  <Table.Cell className="col blocks">
                    <label>Recruiter</label>
                    <Menu dropdown avoidNullOption
                      name="recruiter"
                      value={this.state.accountId || Core.getUserId()}
                      onChange={(accountId) => {
                        const recruiter = this.state.recruiters.find(
                          recruiter => recruiter.id === accountId
                        );
                        this.setState({ accountId, recruiter }, then => {
                          this.getData();
                        });
                      }}
                      paperStyle={{ maxHeight: 640 }}
                      options={this.state.recruiters
                        .sort((a, b) => {
                          if (a.id === "0") {
                            return -1;
                          }
                          a = String(a.firstName || a.email).toLowerCase();
                          b = String(b.firstName || b.email).toLowerCase();
                          return a > b ? 1 : a < b ? -1 : 0;
                        })}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks">
                    <label>Employer</label>
                    <Menu dropdown avoidNullOption
                      name="employer"
                      value={this.state.employerId}
                      onChange={(employerId) => {
                        const employer = this.state.employers.find(
                          employer => employer.id === employerId
                        );
                        this.setState({ employerId, employer }, then => {
                          this.getData();
                        });
                      }}
                      paperStyle={{ maxHeight: 640 }}
                      options={this.state.employers
                        .sort((a, b) => {
                          if (a.id === "0") {
                            return -1;
                          }
                          a = String(a.name).toLowerCase();
                          b = String(b.name).toLowerCase();
                          return a > b ? 1 : a < b ? -1 : 0;
                        })}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks">
                    <label>Duration</label>
                    <Menu dropdown avoidNullOption
                      name="duration"
                      value={this.state.duration}
                      onChange={(duration) => {
                        this.setState({ duration }, then => {
                          if (/Weekly/i.test(duration)) {
                            this.setState(
                              {
                                startDate: moment()
                                  .tz("America/Los_Angeles")
                                  .startOf("Day")
                                  .subtract(15, "weeks")
                                  .startOf("week")
                                  .toISOString(),
                                endDate: moment()
                                  .tz("America/Los_Angeles")
                                  .endOf("day")
                                  .toISOString()
                              },
                              then => {
                                this.getData();
                              }
                            );
                          } else {
                            this.setState(
                              {
                                startDate: moment()
                                  .tz("America/Los_Angeles")
                                  .startOf("Day")
                                  .subtract(11, "months")
                                  .startOf("month")
                                  .toISOString(),
                                endDate: moment()
                                  .tz("America/Los_Angeles")
                                  .endOf("day")
                                  .toISOString()
                              },
                              then => {
                                this.getData();
                              }
                            );
                          }
                        });
                      }}
                      options={["Weekly", "Monthly"]}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks">
                    <label>View By</label>
                    <Menu dropdown avoidNullOption
                      name="viewBy"
                      value={this.state.viewBy}
                      onChange={(viewBy) => {
                        this.setState({ viewBy }, then => {
                          this.getData();
                        });
                      }}
                      paperStyle={{ maxHeight: 640 }}
                      options={["Engagements", "Candidates"]}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks">
                    <label>State Date</label>
                    <DatePicker
                      name="startDate"
                      formatDate={date => moment(date).format(DATE_FORMAT__DATE__ISO)}
                      value={
                        this.state.startDate
                          ? new Date(this.state.startDate)
                          : null
                      }
                      onChange={(startDate) => {
                        this.setState(
                          {
                            startDate: moment.tz(startDate, "America/Los_Angeles")
                              .startOf("day")
                              .toISOString()
                          },
                          then => {
                            this.getData();
                          }
                        );
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks">
                    <label>End Date</label>
                    <DatePicker
                      name="endDate"
                      formatDate={date => moment(date).format(DATE_FORMAT__DATE__ISO)}
                      value={
                        this.state.endDate ? new Date(this.state.endDate) : null
                      }
                      onChange={(endDate) => {
                        this.setState(
                          {
                            endDate: moment.tz(endDate, "America/Los_Angeles")
                              .endOf("day")
                              .toISOString()
                          },
                          then => {
                            this.getData();
                          }
                        );
                      }}
                    />
                  </Table.Cell>
                  <Table.Cell className="col blocks" />
                </Table.Row>
              </Table.Body>
            </Table>
          </Box>
          <Divider />
          <Box column w100 scrollX>
            <Table styled className="collapsed-view">
              <Table.Body
                ref={self => (this.rowsContainer = self)}
              >
                {this.state.rows}
              </Table.Body>
            </Table>
          </Box>
        </Paper>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          title='Engagements'
          content={
            <>
              {this.state.engagementsLoading && <CircularProgress color="inherit" />}
              {
                (
                  this.state.clickedCountEngagements.length > 0
                ) ? (
                  <Table styled className='h-100'>
                    <Table.Head className='sticky-top'>
                      <Table.Row>
                        <Table.Cell
                          className='align-center'
                        >
                          #
                        </Table.Cell>
                        <Table.Cell
                          className='align-center'
                        >
                          Action
                        </Table.Cell>
                        <Table.Cell>Candidate</Table.Cell>
                        <Table.Cell>Employer</Table.Cell>
                        <Table.Cell>Job</Table.Cell>
                        <Table.Cell>Recruiter</Table.Cell>
                        <Table.Cell>Introduced</Table.Cell>
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {this.state.clickedCountEngagements.map((
                        context,
                        index
                      ) => (
                        <Table.Row
                          key={
                            joinKeyName([
                              context.URL__ENGAGEMENT__VIEW,
                              index
                            ])
                          }
                        >
                          <Table.Cell className='p-0 align-center'>
                            {index + 1}
                          </Table.Cell>
                          <Table.Cell className='p-0 align-center'>
                            <IconButton primary icon='visibility'
                              onClick={(event) => Core.openPopUp(context.URL__ENGAGEMENT__VIEW, 1600)}
                              title='Click to see engagement details'
                              placement={PLACEMENT__TOP_START}
                            />
                          </Table.Cell>

                          <Table.Cell className='p-0'>
                            <Button flat primary mr1 minW120
                              onClick={(event) => Core.openPopUp(context.URL__CANDIDATE__EDIT, 1600)}
                              disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }))}
                              className='align-left'
                            >
                              <Icon mr icon='info'
                                className='icon16 c-gray'
                                title={`Candidate: ${context.CANDIDATE__FULLNAME}`}
                                placement={PLACEMENT__TOP_START}
                              />
                              {context.CANDIDATE__FULLNAME}
                            </Button>
                          </Table.Cell>

                          <Table.Cell className='p-0'>
                            <Button flat primary minW120
                              onClick={(event) => Core.openPopUp(context.URL__EMPLOYER__EDIT, 1600)}
                              disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_CANDIDATE }))}
                              className='align-left'
                            >
                              <Icon mr icon='info'
                                className='icon16 c-gray'
                                title={`Employer: ${context.EMPLOYER__NAME}`}
                                placement={PLACEMENT__TOP_START}
                              />
                              {context.EMPLOYER__NAME}
                            </Button>
                          </Table.Cell>

                          <Table.Cell className='p-0'>
                            <Button flat primary minW120
                              onClick={(event) => Core.openPopUp(context.URL__JOB__VIEW, 1600)}
                              disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__LIST_JOBS }))}
                              className='align-left'
                            >
                              <Icon mr icon='info'
                                className='icon16 c-gray'
                                title={`Job: ${context.JOB__JOB_TITLE}`}
                                placement={PLACEMENT__TOP_START}
                              />
                              {context.JOB__JOB_TITLE}
                            </Button>
                          </Table.Cell>

                          <Table.Cell className='p-0'>
                            <Button flat primary minW120
                              onClick={(event) => Core.openPopUp(context.URL__RECRUITER__EDIT, 1600)}
                              disabled={NOT(Core.isAdmin({ action: ACCOUNT_ACTION__EDIT_ACCOUNT }))}
                              className='align-left'
                            >
                              <Icon mr icon='info'
                                className='icon16 c-gray'
                                title={`Recruiter: ${context.RECRUITER__NAME}`}
                                placement={PLACEMENT__TOP_START}
                              />
                              {context.RECRUITER__NAME}
                            </Button>
                          </Table.Cell>

                          <Table.Cell className='p-0'>
                            <Box row>
                              <Icon mr icon='info'
                                className='icon16 c-gray'
                                title={`Introduced: ${context.INTRODUCED_DATE_24H_PT}`}
                                placement={PLACEMENT__TOP_START}
                              />
                              {context.INTRODUCED_DATE}
                            </Box>
                          </Table.Cell>
                        </Table.Row>

                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <Typography>No Records found</Typography>
                )
              }
            </>
          }
          actions={[
            <Button
              onClick={this.handleClose}
              className='min-w-120'
              primary
            >
              Close
            </Button>
          ]}
          paperStyle={{ width: 1600 }}
        />
      </Box>
    );
  }
}
