import {
  COLLECTION__ENGAGEMENTS
} from '../services/BE/loopback.api';

export const QUERY__REPORTS__ENGAGEMENTS = {
  collection: COLLECTION__ENGAGEMENTS,
  fields: [
    'id',
    'candidateId',
    'jobId',
    'stage',
    'introduced',
    'matched',
    'submitted',
    'reviewed',
    'screened',
    'onsite',
    'offered',
    'screen1',
    'screen2',
    'onsite1',
    'onsite2'
  ],
  include: [
    {
      relation: 'candidate',
      scope: {
        fields: [
          'id',
          'accountId',
          'firstName',
          'lastName'
        ],
        include: [
          {
            relation: 'account',
            scope: {
              fields: [
                'firstName',
                'lastName'
              ]
            }
          }
        ]
      }
    },
    {
      relation: 'job',
      scope: {
        fields: [
          'id',
          'jobTitle',
          'employerId'],
        include: [
          {
            relation: 'employer',
            scope: {
              fields: [
                'id',
                'name']
            }
          }
        ]
      }
    }
  ]
};

export default QUERY__REPORTS__ENGAGEMENTS;
